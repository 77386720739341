import React, { useEffect, useState } from 'react';
import cytoscape from 'cytoscape';
import cytoscapeDomNode from 'cytoscape-dom-node';
import CytoscapeComponent from 'react-cytoscapejs';
import axios from 'axios';

// Register the extension
cytoscape.use(cytoscapeDomNode);

const GraphEditor = () => {
  const [elements, setElements] = useState([]);
  const [positions, setPositions] = useState({});

  useEffect(() => {
    const fetchTriples = async () => {
      const query = {
        query: `
          PREFIX ex: <http://example.com/ns#>
          SELECT ?subject ?predicate ?object ?subjectPositionX ?subjectPositionY ?objectPositionX ?objectPositionY ?subjectLabel ?predicateLabel ?objectLabel
          WHERE {
            ?subject ?predicate ?object.
            OPTIONAL { ?subject ex:positionX ?subjectPositionX }.
            OPTIONAL { ?subject ex:positionY ?subjectPositionY }.
            OPTIONAL { ?object ex:positionX ?objectPositionX }.
            OPTIONAL { ?object ex:positionY ?objectPositionY }.
            OPTIONAL { ?subject ex:label ?subjectLabel }.
            OPTIONAL { ?predicate ex:label ?predicateLabel }.
            OPTIONAL { ?object ex:label ?objectLabel }.
            FILTER(?predicate != ex:positionX && ?predicate != ex:positionY && ?predicate != ex:label)
          }
        `,
      };
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      };

      try {
        const response = await axios.post('http://localhost:5000/api/query', query, config);
        const { data } = response;

        var nodes = {}; // Store for nodes to accumulate properties
        var edges = []; // Store for edges

        data.results.bindings.forEach(binding => {
          const subjectId = binding.subject.value;
          const objectId = binding.object.value;
          const predicate = binding.predicate.value;
          const predicateLabel = binding.predicateLabel ? binding.predicateLabel.value : predicate.split('example.org/').pop();
          const objectValue = binding.objectLabel ? binding.objectLabel.value : objectId.split('example.org/').pop();

          // Initialize subject node if it doesn't exist
          if (!nodes[subjectId]) {
            nodes[subjectId] = {
              id: subjectId,
              label: binding.subjectLabel ? binding.subjectLabel.value : subjectId.split('example.org/').pop(),
              properties: [],
              position: {
                x: binding.subjectPositionX ? parseFloat(binding.subjectPositionX.value) : Math.random() * 800,
                y: binding.subjectPositionY ? parseFloat(binding.subjectPositionY.value) : Math.random() * 600,
              },
            };
          }

          // Add properties or create edges
          if (objectId.startsWith('http://') || objectId.startsWith('https://')) {
            // It's a URL object; create a new node if it doesn't exist and an edge
            if (!nodes[objectId]) {
              nodes[objectId] = {
                id: objectId,
                label: objectValue,
                properties: [],
                position: {
                  x: binding.objectPositionX ? parseFloat(binding.objectPositionX.value) : Math.random() * 800,
                  y: binding.objectPositionY ? parseFloat(binding.objectPositionY.value) : Math.random() * 600,
                },
              };
            }
            edges.push({
              id: `edge-${subjectId}-${objectId}`,
              source: subjectId,
              target: objectId,
              label: predicateLabel,
            });
          } else {
            // It's a literal; add it to the subject node's properties
            nodes[subjectId].properties.push({
              predicateLabel,
              objectValue,
            });
          }
        });

        // Convert nodes and properties to Cytoscape elements
        const cyElements = Object.values(nodes).map(node => {
          let nodeData = {
            group: 'nodes',
            data: { ...node, id: node.id },
            position: node.position
          };

          if (node.properties.length > 0) {
            // Creating table with a title (node label) and updated styles for table intransparency and internal borders
            const tableHtml = `<thead><tr><th colspan="2" style="text-align:center;">${node.label}</th></tr></thead>` +
                              `<tbody>` +
                              node.properties.map(prop => `<tr><td style="border: 1px solid black; font-size:10px;">${prop.predicateLabel}</td><td style="border: 1px solid black;">${prop.objectValue}</td></tr>`).join('') +
                              `</tbody>`;
            const content = `<table style="border-collapse: collapse; width: 100%;  padding: 10px; box-shadow: 0px 0px 5px rgba(0,0,0,0.2); border-radius: 4px; overflow: hidden; background-color: #fff; font-size:10px;">${tableHtml}</table>`;

            const div = document.createElement('div');
            div.innerHTML = content;
            document.body.appendChild(div); // Append to body for measurement

            nodeData = {
              ...nodeData,
              data: { ...nodeData.data, dom: div }
            };
          }

          return nodeData;
        }).concat(edges.map(edge => ({
          group: 'edges',
          data: {
            id: edge.id,
            source: edge.source,
            target: edge.target,
            label: edge.label
          }
        })));

        setElements(cyElements);
      } catch (error) {
        console.error('Error fetching triples:', error);
      }
    };

    fetchTriples();
  }, []);


  const updatePosition = async (nodeId, newPosition) => {
    // Construct SPARQL update query to delete existing ex:positionX and ex:positionY properties for the specific node
    const deleteQuery = `
      PREFIX ex: <http://example.com/ns#>
      DELETE {
        <${nodeId}> ex:positionX ?oldPositionX ;
                    ex:positionY ?oldPositionY .
      } WHERE {
        <${nodeId}> ex:positionX ?oldPositionX ;
                    ex:positionY ?oldPositionY .
      }
    `;

    // Construct SPARQL update query to insert new ex:positionX and ex:positionY properties for the specific node
    const insertQuery = `
      PREFIX ex: <http://example.com/ns#>
      INSERT DATA {
        <${nodeId}> ex:positionX ${newPosition.x} ;
                    ex:positionY ${newPosition.y} .
      }
    `;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };

    try {
      // First, delete the existing position properties
      await axios.post('http://localhost:5000/api/update', { query: deleteQuery }, config);

      // Then, insert the new position values
      await axios.post('http://localhost:5000/api/update', { query: insertQuery }, config);
      console.log('Position updated successfully');
    } catch (error) {
      console.error('Error updating position:', error);
    }
  };

  const handleNodePositionChange = (event) => {
    const { target } = event;
    const nodeId = target.id();
    const newPos = target.position();

    // Update the positions state variable
    setPositions(prevPositions => ({
      ...prevPositions,
      [nodeId]: newPos
    }));

    // Immediately update elements to reflect the new position
    setElements(prevElements => prevElements.map(el => {
      if (el.data.id === nodeId) {
        // Update position for node elements
        return {
          ...el,
          position: newPos, // Update this to correctly reflect how your elements store positions
          data: {
            ...el.data,
            position: newPos // This assumes your node data structure includes a 'position' field
          }
        };
      }
      return el;
    }));
  };

  useEffect(() => {
    // Iterate over the positions state to update positions in the backend
    Object.entries(positions).forEach(([nodeId, position]) => {
      updatePosition(nodeId, position);
    });
  }, [positions]);

  const style = {
    width: '100vw',
    height: 'calc(100vh - 50px)',
    marginTop: '50px'
  };

  const stylesheet = [
    {
      selector: 'node',
      style: {
        'label': 'data(label)',
        'text-valign': 'center',
        'text-halign': 'center',
        'font-size': '10px',
        'color': '#333',
        'text-outline-color': '#fff',
        'text-outline-width': '2px',
        'text-wrap': 'wrap',
        'text-max-width': '100px',
        'background-color': '#fff',
        'border-color': '#000',
        'border-width': '5px',
        'border-opacity': '0.2',
        'padding': '7px',
      }
    },
    {
      selector: 'edge',
      style: {
        'label': 'data(label)',
        'text-valign': 'center',
        'text-halign': 'center',
        'font-size': '10px',
        'color': '#333',
        'text-outline-color': '#fff',
        'text-outline-width': '2px',
        'text-wrap': 'wrap',
        'text-max-width': '100px',
        'curve-style': 'bezier',
        'target-arrow-shape': 'triangle', // This adds an arrow to the edge pointing to the target node
        'target-arrow-color': '#000', // Arrow color
        'line-color': '#000', // Edge line color
      }
    }
  ];

  return (
    <CytoscapeComponent
      elements={elements}
      style={style}
      stylesheet={stylesheet}
      cy={(cy) => {
        // Initialize the extension with the cy instance
        cy.domNode();

        // Your existing event handlers
        cy.on('dragfree', handleNodePositionChange);

        cy.ready(() => {
          elements.forEach(element => {
            if (element.data && element.data.position) {
              const { id, position } = element.data;
              cy.getElementById(id).position(position);
            }
          });
        });
      }}
    />
  );
};

export default GraphEditor;
