import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme'; // Ensure this path matches where your theme is defined
import ProtectedRoute from './utils/ProtectedRoute'; // Assuming you have a similar ProtectedRoute component
import LoginPage from './components/LoginPage'; // Assuming you have a similar LoginPage component
import GraphEditor from './components/GraphEditor';

const App = () => (
  <ThemeProvider theme={theme}> {/* Wrap the entire Router with ThemeProvider */}
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/editor" element={<ProtectedRoute><GraphEditor /></ProtectedRoute>} />
        <Route path="/" element={<Navigate replace to="/editor" />} />
      </Routes>
    </Router>
  </ThemeProvider>
);

export default App;
